import { useState } from 'react'
import './App.css'
import {BrowserRouter as Main} from 'react-router-dom'
import ApplicationRoutes from './Routes'

function App() {
  

  return (
    <>
      <Main>
        <ApplicationRoutes/>
      </Main>
    </>
  )
}

export default App
