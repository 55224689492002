import React,{useState} from 'react';
import '../Pages/Home.css';
import mm1 from "../assets/mm1.png";
import mm2 from "../assets/mm2.png";
import picture1 from "../assets/picture1.png";
import picture2 from "../assets/picture2.png";
import picture3 from "../assets/picture3.png";
import picture4 from "../assets/picture4.png";
import Ellipse from "../assets/Ellipse.png";
import people from '../assets/people.png';





const Home = () => {
    const causes = [
        {
            category: "Medical",
            title: "Donate For Poor Peoples Treatment And Medicine.",
            description: "Lorem Ipsum Dolor Sit Amet, Consecte Sadipscing Elitr, Sed Diam Nonumy....",
            imageUrl: picture1,
        },
        {
            category: "Homeless",
            title: "Children We Work With",
            description: "Lorem Ipsum Dolor Sit Amet, Consecte Sadipscing Elitr, Sed Diam Nonumy....",
            imageUrl: picture2,
        },
        {
            category: "Education",
            title: "Help For Education",
            description: "Lorem Ipsum Dolor Sit Amet, Consecte Sadipscing Elitr, Sed Diam Nonumy....",
            imageUrl: picture3,
        },
        {
            category: "Food",
            title: "Help For Food",
            description: "Lorem Ipsum Dolor Sit Amet, Consecte Sadipscing Elitr, Sed Diam Nonumy....",
            imageUrl: picture4,
        },

    ];
    const testimonials = [
        {
            name: 'Mandalapu Srirama Murthy',
            role: 'Founder',
            image: people, // Replace with the actual image path
            testimonial: 'Whalefish Zebra Lionfish Mud Cat Pelican Eel. Minnow Snoek Icefish Velvet-Belly Shark, California Halibut Round Stingray Northern Sea Robin. Southern Grayling Trout-PerchSharksucker Sea Toad Candiru Rocket Danio Tilefish Stingray Deepwater Stingray Sacramento Splittail, Canthigaster Rostrata.',
        },
        {
            name: 'Srirama Murthy',
            role: 'Founder',
            image: people, // Replace with the actual image path
            testimonial: 'Sea Chub Demoiselle Whalefish Zebra Lionfish Mud Cat Pelican Eel. Minnow Snoek Icefish Velvet-Belly Shark, California Halibut Round Stingray Northern Sea Robin. Southern Grayling Trout-PerchSharksucker Sea Toad Candiru Rocket Danio Tilefish Stingray Deepwater Stingray Sacramento Splittail, Canthigaster Rostrata.',
        },
        {
            name: 'Mandalapu Srirama Murthy',
            role: 'Founder',
            image: people, // Replace with the actual image path
            testimonial: 'Sea Chub Demoiselle Whalefish Zebra Lionfish Mud',
        },
        // Add more testimonials if needed
    ];

    const articles = [
        {
            id: 1,
            author: 'Esther Howard',
            date: '12 Sep 2021',
            title: 'Charity, Expectations Vs. Reality',
            description: 'Lorem Ipsum Dolor Sit Amet, Consectetur Sadipscing Sed Diam Nonumy Tempor Invidunt Ut Labore Et Magna Aliquyam Erat...',
            image: picture1,
        },
        {
            id: 2,
            author: 'Esther Howard',
            date: '12 Sep 2021',
            title: 'Charity, Expectations Vs. Reality',
            description: 'Lorem Ipsum Dolor Sit Amet, Consectetur Sadipscing Sed Diam Nonumy Tempor Invidunt Ut Labore Et Magna Aliquyam Erat...',
            image: picture1,
        },
        {
            id: 3,
            author: 'Esther Howard',
            date: '12 Sep 2021',
            title: 'Charity, Expectations Vs. Reality',
            description: 'Lorem Ipsum Dolor Sit Amet, Consectetur Sadipscing Sed Diam Nonumy Tempor Invidunt Ut Labore Et Magna Aliquyam Erat...',
            image: picture1,
        },
        {
            id: 4,
            author: 'Esther Howard',
            date: '12 Sep 2021',
            title: 'Charity, Expectations Vs. Reality',
            description: 'Lorem Ipsum Dolor Sit Amet, Consectetur Sadipscing Sed Diam Nonumy Tempor Invidunt Ut Labore Et Magna Aliquyam Erat...',
            image: picture1,
        },
        {
            id: 3,
            author: 'Esther Howard',
            date: '12 Sep 2021',
            title: 'Charity, Expectations Vs. Reality',
            description: 'Lorem Ipsum Dolor Sit Amet, Consectetur Sadipscing Sed Diam Nonumy Tempor Invidunt Ut Labore Et Magna Aliquyam Erat...',
            image: picture1,
        },
        
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
    return (
        < div className='Mandapam-main-page'>
            <section className="home-section">
                <div className="home-section-content">
                    <div className="text-section">
                        <div className='main-header'>
                            <h1>Empowering Lives, <br /> Building Communities</h1>
                            <p>Join the <span className="highlight">Mandalapu & Mandadapu</span> Welfare Association</p>
                            <button className="join-button">Join Now</button>
                        </div>
                        <div className="large-image">
                            <img src={mm1} alt="Hands Together" />
                        </div>
                    </div>

                    <div className="about-section">
                        <div className="image-wrapper">
                            <img src={mm2} alt="Community Support" />
                        </div>
                        <div>
                            <h2>About Us</h2>
                            <h1>Your Support is Really Powerful.</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                <br />
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <button className="read-more-button">Read More</button>
                        </div>
                    </div>
                </div>
            </section>


            <section className="causes-section">
                <div className="header">
                    <h4>Latest Causes</h4>
                    <h2>Find the popular cause <br /> and donate them</h2>
                </div>

                <div className="cards-container">
                    {causes.map((cause, index) => (
                        <div className="home-card" key={index}>
                            <img src={cause.imageUrl} alt={cause.category} className="card-image" />
                            <div className="card-content">
                                <div className='card-content-det'>
                                <p className="card-category">{cause.category}</p>
                                <h3 className="card-title">{cause.title}</h3>
                                <p className="card-description">{cause.description}</p>
                                </div>
                                <button className="donate-button">Donate Now</button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="navigation">
                    <button className="nav-button">&#8249;</button>
                    <button className="nav-button">&#8250;</button>
                </div>
            </section>


            <div className="testimonial-carousel">
                    <h3 className="subheading">Our Testimonials</h3>
                    <h2 className="heading">What People Say</h2>

                    <div className="testimonial-content">
                        <button onClick={handlePrev} className="carousel-button left">&lt;</button>

                        <div className="testimonial">
                            <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} className="profile-image" />
                            <h3 className="name">{testimonials[currentIndex].name}</h3>
                            <p className="role">{testimonials[currentIndex].role}</p>
                            <p className="testimonial-text">{testimonials[currentIndex].testimonial}</p>
                        </div>

                        <button onClick={handleNext} className="carousel-button right">&gt;</button>
                    </div>

                    <div className="dots">
                        {testimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                </div>


            <section className="article-carousel">
                <h2 className="carousel-heading">Latest News</h2>
                <h3 className="carousel-subheading">Article You May Read</h3>
                <div className="article-cards-container">
                    {articles.map((article) => (
                        <div key={article.id} className="article-card">
                            <img src={article.image} alt="Article" className="article-image" />
                            <div className="article-info">
                                <div className="author-date">
                                    <span className="author"><i className="fas fa-user"></i> {article.author}</span>
                                    <span className="date"><i className="fas fa-calendar-alt"></i> {article.date}</span>
                                </div>
                                <h4 className="article-title">{article.title}</h4>
                                <p className="article-description">{article.description}</p>
                                <button className="read-more-button">Read More <i className="fas fa-arrow-right"></i></button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="carousel-controls">
                    <button className="control prev">&#8249;</button>
                    <button className="control next">&#8250;</button>
                </div>
            </section>
        </div>
    );
};

export default Home;

