import { Route, Routes } from "react-router-dom";
import Layout from "../Layout";
import Home from "../Pages/Home";
import About from "../Pages/About/About";
import Ourteam from "../Pages/Ourteam/Ourteam";
import Blog from "../Pages/Blog/Blog";
import Contactus from "../Pages/Contactus/Contactus";
import Member from "../Pages/Member/Member";


export default function ApplicationRoutes() {
  return (
    <>
     
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/ourteam" element={<Ourteam />} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/contact" element={<Contactus />} />
            <Route path="/member" element={<Member/>} />
          </Routes>
        </Layout>

    </>

  );
}
