import React from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";

export default function AppLayout(props) {
    return (
        <div>
           { <Navbar /> } 
            {props.children}
            <Footer />
        </div>
    );
}
