import React from 'react'
import '../../Pages/Contactus/Contactus.css';
import cntus from '../../assets/cnt us.png'
const App = () => {
    return (
        <div>
            <div className="ot-first-cont" >
                <img src={cntus} alt='' />
            </div>
            <div className="contact-container">
                <div className="contact-header-container">
                    <p className="contact-header" style={{ margin: "0px" }}>Get In Touch</p>
                    <h1 className="contact-subheader" style={{ margin: "0px" }}>Send us a message</h1>
                </div>
                <div className='contact-form-container'>
                    <form className="contact-form">
                        <div className="contact-form-row">
                            <label>
                                First Name:
                                <input type="text" placeholder="Your First Name" className="contact-input" />
                            </label>
                            <label>
                                Last Name:
                                <input type="text" placeholder="Your Last Name" className="contact-input" />
                            </label>
                        </div>
                        <div className="contact-form-row">
                            <label>
                                Email:
                                <input type="email" placeholder="Your Email" className="contact-input" />
                            </label>
                            <label>
                                Phone:
                                <input type="tel" placeholder="+91" className="contact-input" />
                            </label>
                        </div>
                        <label>
                            Message:
                            <textarea placeholder="Type Your Message Here..." className="contact-textarea"></textarea>
                        </label>
                    </form>
                    <div className="contact-address">
                        <h3>Address</h3>
                        <div className="contact-address-details">
                            <p><strong>Location:</strong> D.No:40-28-6/2, Vsrireddy Street, Gayatri Nagar, Vijayawada, Krishna NTR District, Andhra Pradesh State, India.</p>
                            <p><strong>Phone:</strong> +91 98745 01234</p>
                            <p><strong>Email:</strong> nsm@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default App