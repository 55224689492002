import React from 'react';
import '../About/About.css';
import mm2 from "../../assets/mm2.png";
import about1 from '../../assets/about1.png';
import abt1 from "../../assets/abt1.png"
import { FaHome, FaDollarSign, FaHandshake } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const About = () => {

    const features = [
        {
            icon: <FaHome />,
            title: "Food & Homeless Charity",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            points: [
                "Join Your Hand With Us For A Better Life",
                "Let’s Do The Right Thing Now",
            ],
        },
        {
            icon: <FaDollarSign />,
            title: "Make A Donation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            points: [
                "Join Your Hand With Us For A Better Life",
                "Let’s Do The Right Thing Now",
            ],
        },
        {
            icon: <FaHandshake />,
            title: "Non Profit NGO",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            points: [
                "Join Your Hand With Us For A Better Life",
                "Let’s Do The Right Thing Now",
            ],
        },
        {
            icon: <FaHome />,
            title: "Food & Homeless Charity",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            points: [
                "Join Your Hand With Us For A Better Life",
                "Let’s Do The Right Thing Now",
            ],
        },
    ];

    const images = [
        about1,
        about1,
        about1,
        about1,
        about1,
        about1,
        about1,
        about1,
      ];
      const navigate = useNavigate();
    return (
      
        <div>
            <div className="about-us-container">
            <img src={abt1} alt='' />
            </div>

            <section className="home-section">
                <div className="home-section-content">
                    <div className="about-section">
                        <div className="image-wrapper">
                            <img src={mm2} alt="Community Support" />
                        </div>
                        <div>
                            <h2>About Us</h2>
                            <h1>Your Support is Really Powerful.</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                <br />
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <button className="read-more-button">Read More</button>
                        </div>
                    </div>
                </div>
            </section>

            <div className="charity-section">
                <div className="text21-section">
                    <h2>Welcome To Charity</h2>
                    <h1>Let Us Come Together To Make a Difference</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <div className="cards">
                        <div className="card">
                            <h3>Our Mission</h3>
                            <p>Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr,
                                sed diam</p>
                        </div>
                        <div className="card">
                            <h3>Our Vision</h3>
                            <p>Lorem ipsum dolor sit amet,
                                consetetur sadipscing elitr,
                                sed diam</p>
                        </div>
                    </div>
                </div>
                <div className="image-section">
                    {/* <img src={mm2} alt="Main" className="main-image" /> */}
                    <img src={mm2} alt="Hands" className="hands-image" />
                </div>
            </div>

            <div className="features-section">
                <h2>About</h2>
                <h1>Our Features</h1>
                <div className="features-container">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-card">
                            <div className="feature-icon">{feature.icon}</div>
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                            <ul>
                                {feature.points.map((point, idx) => (
                                    <li key={idx}>{point}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>


            <div className="gallery-section">
                <h2 className="gallery-title">Our Gallery</h2>
                <div className="gallery-grid">
                    {images.map((image, index) => (
                        <div key={index} className="gallery-item">
                            <img src={image} alt={`Gallery ${index + 1}`} className="gallery-image" />
                        </div>
                    ))}
                </div>
                <button className="view-more-button" onClick={() => { navigate("/ourteam")}}>View More</button>
            </div>

        </div>
    )
}

export default About;
