import React, { useState } from 'react';
import blog from '../../assets/Blog.svg';
import '../../Pages/Blog/Blog.css';
import donate from '../../assets/Donate.svg';
import person from '../../assets/person.svg';
import calender from '../../assets/calender.svg'
import clock from '../../assets/clock.svg'
import donate1 from '../../assets/Donate.png'
import people from '../../assets/people.png';


const testimonials = [
    {
        name: 'Mandalapu Srirama Murthy',
        role: 'Founder',
        image: people, // Replace with the actual image path
        testimonial: 'Whalefish Zebra Lionfish Mud Cat Pelican Eel. Minnow Snoek Icefish Velvet-Belly Shark, California Halibut Round Stingray Northern Sea Robin. Southern Grayling Trout-PerchSharksucker Sea Toad Candiru Rocket Danio Tilefish Stingray Deepwater Stingray Sacramento Splittail, Canthigaster Rostrata.',
    },
    {
        name: 'Srirama Murthy',
        role: 'Founder',
        image: people, // Replace with the actual image path
        testimonial: 'Sea Chub Demoiselle Whalefish Zebra Lionfish Mud Cat Pelican Eel. Minnow Snoek Icefish Velvet-Belly Shark, California Halibut Round Stingray Northern Sea Robin. Southern Grayling Trout-PerchSharksucker Sea Toad Candiru Rocket Danio Tilefish Stingray Deepwater Stingray Sacramento Splittail, Canthigaster Rostrata.',
    },
    {
        name: 'Mandalapu Srirama Murthy',
        role: 'Founder',
        image: people, // Replace with the actual image path
        testimonial: 'Sea Chub Demoiselle Whalefish Zebra Lionfish Mud',
    },
    // Add more testimonials if needed
];

const Blog = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
    return (
        <>
            <img src={blog} alt='blog' className='blog-img' />
            <div className='donate-container'>
                <div class="Donate-container">
                    <div className='donate-left'>
                        <h2>Donating Poor People.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br></br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <div className='donate-left-content'>
                            <div className='donate-left-icons'>
                                <img src={person} alt="" />
                                <span>Esther Howard</span>
                            </div>
                            <div className='donate-left-icons'>
                                <img src={calender} alt="" />
                                <span>12 sep 2024</span>
                            </div>
                            <div className='donate-left-icons'>
                                <img src={clock} alt="" />
                                <span>10 min read</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={donate1} alt="" className='donate-img' />
                    </div>
                </div>
                <div className='blog2-container'>
                    <div className='blog2-header'>
                        <h2>Back to the future: Quality education through respect,commitment and accountability</h2>
                    </div>
                    <div className='blog2-paragraph'>
                        <p>Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.” Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.” Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.”</p>
                    </div>
                    <div className='blog2-paragraph'>
                        <p>Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.”</p>
                    </div>
                </div>
                <div class="Donate-container">
                    <div>
                        <img src={donate1} alt="" className='donate-img2' />
                    </div>
                    <div className='donate-left2'>
                        <h2>Clean water helps keep kids in school, especially girls.</h2>
                        <div className='donate-left-content'>
                            Less time collecting water means more time in class. Clean water and proper toilets at school means teenage girls don’t have to stay home for a week out of every month. Northern anchovy–bass yellowtail barracuda zander yellowfin grouper gurnard skipjack tuna shark goby eulachon wobbegong. Nase combtail gourami amur pike flabby whalefish; darter, Blind Eagle ray soapfish ocean sunfish filefish, barbel sandfish wolf-herring northern pike roach barbelless catfish, Atlantic saury mackerel shark pike conger. Blind shark longfin smelt pearl bent-tooth eel stargazer grunion spookfish yellowtail Quillfish slickhead mora. Springfish worm mackerel sockeye salmon banjo catfish toadfish sauger four-eyed fish
                        </div>
                        <br></br>
                        <div className='donate-left-content'>
                            Less time collecting water means more time in class. Clean water and proper toilets at school means teenage girls don’t have to stay home for a week out of every month. Northern anchovy–bass yellowtail barracuda zander yellowfin grouper gurnard skipjack tuna shark goby eulachon wobbegong. Nase combtail gourami amur pike flabby whalefish; darter, Blind Eagle ray soapfish ocean sunfish filefish, barbel sandfish wolf-herring northern pike roach barbelless catfish, Atlantic saury mackerel shark pike conger. Blind shark longfin smelt pearl bent-tooth eel stargazer grunion spookfish yellowtail Quillfish slickhead mora. Springfish worm mackerel sockeye salmon banjo catfish toadfish sauger four-eyed fish
                        </div>
                    </div>

                </div>

                <div className="testimonial-carousel">
                    <h3 className="subheading">Our Testimonials</h3>
                    <h2 className="heading">What People Say</h2>

                    <div className="testimonial-content">
                        <button onClick={handlePrev} className="carousel-button left">&lt;</button>

                        <div className="testimonial">
                            <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} className="profile-image" />
                            <h3 className="name">{testimonials[currentIndex].name}</h3>
                            <p className="role">{testimonials[currentIndex].role}</p>
                            <p className="testimonial-text">{testimonials[currentIndex].testimonial}</p>
                        </div>

                        <button onClick={handleNext} className="carousel-button right">&gt;</button>
                    </div>

                    <div className="dots">
                        {testimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                </div>
                <div class="Donate-container">
                    <div className='donate-left'>
                        <h2>Trouble With The Law Since The Day</h2>
                        <div className='donate-left-content'>
                            Less time collecting water means more time in class. Clean water and proper toilets at school means teenage girls don’t have to stay home for a week out of every month. Northern anchovy–bass yellowtail barracuda zander yellowfin grouper gurnard skipjack tuna shark goby eulachon wobbegong. Nase combtail gourami amur pike flabby whalefish; darter, Blind Eagle ray soapfish ocean sunfish filefish, barbel sandfish wolf-herring northern pike roach barbelless catfish, Atlantic saury mackerel shark pike conger. Blind shark longfin smelt pearl bent-tooth eel stargazer grunion spookfish yellowtail Quillfish slickhead mora. Springfish worm mackerel sockeye salmon banjo catfish toadfish sauger four-eyed fish
                        </div>
                        <br></br>
                        <div className='donate-left-content'>
                            Less time collecting water means more time in class. Clean water and proper toilets at school means teenage girls don’t have to stay home for a week out of every month. Northern anchovy–bass yellowtail barracuda zander yellowfin grouper gurnard skipjack tuna shark goby eulachon wobbegong. Nase combtail gourami amur pike flabby whalefish; darter, Blind Eagle ray soapfish ocean sunfish filefish, barbel sandfish wolf-herring northern pike roach barbelless catfish, Atlantic saury mackerel shark pike conger. Blind shark longfin smelt pearl bent-tooth eel stargazer grunion spookfish yellowtail Quillfish slickhead mora. Springfish worm mackerel sockeye salmon banjo catfish toadfish sauger four-eyed fish
                        </div>
                    </div>
                    <div>
                        <img src={donate1} alt="" className='donate-img2' />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Blog