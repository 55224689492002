import React, { useEffect } from 'react';
import "../../Pages/Ourteam/Ourteam.css";
import ourteam from '../../assets/Frame.png';
import img1 from '../../assets/img 1.png';
import img2 from '../../assets/img 2.png';
import img3 from '../../assets/img 3.png';
import img4 from '../../assets/img 4.png';
import img5 from '../../assets/img 5.png';
import img6 from '../../assets/img 6.png';
import img7 from '../../assets/img 7.png';
import img8 from '../../assets/img 8.png';
import img9 from '../../assets/img 9.png';
import img10 from '../../assets/img 10.png';
import img11 from '../../assets/img 11.png';
import img12 from '../../assets/img 12.png';
import img13 from '../../assets/img 13.png';
import img14 from '../../assets/img 14.png';
const images = [
    img1, img2, img3, img4, img5, img6, img7,
    img8, img9, img10, img11, img12, img13, img14
];
const members = [
    { id: 1, name: "M. Srirama Murthy, Kondalarao Palem.", amount: "1,00,000" },
    { id: 2, name: "M. Raghava Rao, Hyderabad.", amount: "1,00,000" },
    { id: 3, name: "M. Venkata Rao, Sankara Puram.", amount: "1,00,000" },
    { id: 4, name: "M. Sudhakara Rao, Madhira.", amount: "1,00,000" },
    { id: 5, name: "Dr. M. Ekambaram Naidu, Pakala - Chittoor", amount: "1,00,000" },
    { id: 6, name: "M. Babu Rao, Vijayawada", amount: "1,00,000" },
    { id: 7, name: "M. Venkateswara Rao, Eluru.", amount: "1,00,000" },
    { id: 8, name: "M. Srinivasa Rao, Madhira.", amount: "1,00,000" },
    { id: 9, name: "M. Koteswara Rao, Guntur", amount: "1,00,000" },
    { id: 10, name: "M. Srinivasa Rao, Teacher - Guntur.", amount: "1,00,000" },
    { id: 11, name: "M. Krishna Rao, Guntur.", amount: "1,00,000" },
    { id: 12, name: "M. Mallikarjuna Rao, Vijayawada.", amount: "1,00,000" },
    { id: 13, name: "M. Prakash, Chennai - Tamilnadu.", amount: "1,00,000" },
];
const goldMembers = [
    { id: 1, name: "M. Venkata Rao, Chuttugunta.", amount: "50,000" },
    { id: 2, name: "M. Srinivasa Rao, Vijayawada.", amount: "50,000" },
    { id: 3, name: "M. Chitti Babu, Nagiri, Chittoor.", amount: "50,000" },
    { id: 4, name: "M. Shiva Kumar, NTR District.", amount: "50,000" },
    { id: 5, name: "Dr. Natarajan, Tamilnadu.", amount: "50,000" },
    { id: 6, name: "M S R K Prasad, Hyderabad.", amount: "50,000" },
];
const silverMembers = [
    { id: 1, name: "M. Srinivasa Rao (Medical), Vijayawada.", amount: "25,000" },
    { id: 2, name: "M. Kanta Rao, Shankarapuram", amount: "25,000" },
    { id: 3, name: "M. Tirupata Rao, Shankarapuram", amount: "25,000" },
    { id: 4, name: "M. Venkata Mohan Rao, Vijayawada, Madhira.", amount: "25,000" },
];
const bronzeMembers = [
    { id: 1, name: "M. Padma ACTO, Arlapadu, NTR.", amount: "5,000" },
];
function App() {
    useEffect(() => {
        window.scrollTo(0, 0); 
      },[]);
   
    return (
        <>
            <div className="ot-first-cont" >
                <img src={ourteam} alt='' />
            </div>
            <div className='ot-second-cont'>
                <h2>OUR TEAM</h2>
                <p>expert members</p>
                <div className="image-gallery">
                    {images.map((otim, index) => (
                        <img key={index} src={otim} alt={`Image ${index + 1}`} />
                    ))}
                </div>
            </div>
            <div className='ot-third-cont'>
                <h4>Other Members</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: "24px" }}>
                    <div className="members">
                        <div className="diamond-members">
                            <h3>Diamond Members</h3>
                            <ul>
                                {members.map((member) => (
                                    <li key={member.id}>
                                        <span className="ot-name">{`${member.id}. ${member.name}`}</span>
                                        <span className="ot-amount">{member.amount}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='other-members'>
                            <div className="membership-section gold-members">
                                <h3>Gold Members</h3>
                                <ul>
                                    {goldMembers.map((member) => (
                                        <li key={member.id}>
                                            <span className="ot-name">{`${member.id}. ${member.name}`}</span>
                                            <span className="ot-amount">{member.amount}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="membership-section silver-members">
                                <h3>Silver Members</h3>
                                <ul>
                                    {silverMembers.map((member) => (
                                        <li key={member.id}>
                                            <span className="ot-name">{`${member.id}. ${member.name}`}</span>
                                            <span className="ot-amount">{member.amount}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="membership-section bronze-members">
                        <h3>Bronze Members</h3>
                        <ul>
                            {bronzeMembers.map((member) => (
                                <li key={member.id}>
                                    <span className="ot-name">{`${member.id}. ${member.name}`}</span>
                                    <span className="ot-amount">{member.amount}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
export default App;