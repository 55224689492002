import React ,{useState}from 'react'
import logo from "../assets/logo-mm.png";
import { useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';


const Navbar = () => {
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
        <div className="navbar">
            <div className="navbar-logo" onClick={() => { navigate("/") }}>
                <img src={logo} alt="Logo" className="logo" />
                <span className="logo-text">Mandalapu & Mandadapu</span>
            </div>

            {/* Hamburger Icon */}
            <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>

            <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
                <li className="nav-item" onClick={() => { navigate("/"); setIsMenuOpen(false); }}>Home</li>
                <li className="nav-item dropdown" onClick={() => { navigate("/aboutus"); setIsMenuOpen(false); }}>
                    About Us
                </li>
                <li className="nav-item" onClick={() => { navigate("/blog"); setIsMenuOpen(false); }}>Blog</li>
                <li className="nav-item dropdown">
                    Get Involved
                    <ul className="dropdown-content">
                        <li onClick={() => { navigate("/member"); setIsMenuOpen(false); }}>Become a Member</li>
                        <li onClick={() => { setIsMenuOpen(false); }}>Donate</li>
                    </ul>
                </li>
                <li className="nav-item" onClick={() => { navigate("/ourteam"); setIsMenuOpen(false); }}>Our Team</li>
                <li className="nav-item" onClick={() => { navigate("/contact"); setIsMenuOpen(false); }}>Contact Us</li>
            </ul>
        </div>
    </div>
    )
}

export default Navbar;
