import React from 'react';
import "../Member/Member.css";
import become from '../../assets/become.png';
import donate1 from '../../assets/Donate.png'

const Member = () => {
    return (
        <>
            <div className='member-img'>
                <img src={become} alt='' />
            </div>
            <div class="mem-container">
                <div className='mem-left'>
                    <h2>Join us in making a difference!</h2>
                    <p>By becoming a member of the Mandalapu & Mandadapu Welfare Association, you'll be part of a community dedicated to uplifting our family and making a positive impact on society.</p>
                    <h4>Why Join Us</h4>
                    <ul>
                        <li>Support our mission: Contribute to our efforts to provide education, healthcare, and community development.</li>
                        <li>Network with like-minded individuals: Connect with other members who share your passion for our family's heritage and future.</li>
                        <li>Stay informed: Receive regular updates on our activities, events, and achievements.</li>
                        <li>Participate in our initiatives: Volunteer your time and skills to support our various programs.</li>
                    </ul>

                </div>
                <div>
                    <img src={donate1} alt="" className='become-img' />
                </div>
            </div>

            <div className='member1-container'>
                <div className='mem-header'>
                    <h2>Membership Levels</h2>
                </div>
                <div className='mem-paragraph'>
                    <p>Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.” Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.” Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.”</p>
                </div>
                <div className='mem-paragraph'>
                    <p>Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach, “snake mackerel bonytail chub arapaima horsefish weasel shark.”</p>
                </div>
            </div>

            <div className="membership-info">
                <div className="membership-tables">
                    <div className="membership-table">
                        <h3>Indian Members</h3>
                        <ul>
                            <li>1. Farmer Members</li>
                            <li>2. Bronze Members</li>
                            <li>3. Copper Members</li>
                            <li>4. Silver Members</li>
                            <li>5. Gold Members</li>
                            <li>6. Diamond Members</li>
                        </ul>
                    </div>
                    <div className="membership-table">
                        <h3>NRI Members</h3>
                        <ul>
                            <li>1. NRI Bronze Members</li>
                            <li>2. NRI Copper Members</li>
                            <li>3. NRI Silver Members</li>
                            <li>4. NRI Gold Members</li>
                            <li>5. NRI Diamond Members</li>
                            <li>6. NRI Patrons & Donors</li>
                        </ul>
                    </div>
                </div>
                <div className="registered-office">
                    <h3>Registered Office</h3>
                    <p><strong>Community Name:</strong> Mandalapu & Mandadapu Welfare Association</p>
                    <p><strong>Address:</strong><br />
                        D.No.60-28-6/2, Vasireddy Street, Gayatri Nagar, <br />
                        Vijayawada, Krishna NTR District, <br />
                        Andhra Pradesh State, India.
                    </p>
                </div>
            </div>

        </>

    )
}


export default Member