import React from 'react';
import Footer from "../assets/Footer.png";
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/Footer.css';
import { useNavigate } from 'react-router-dom';

const Footeer = () => {
    const navigate = useNavigate();
    return (
        <div>
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-logo">
                        <img
                            src={Footer}
                            alt="Organization Logo"
                            className="logo-img"
                        />
                    </div>
                    <div className='footer-content1'>
                    <div className='footer-content2'>

                    <div className="footer-section quick-links">
                        <h3>Quick Link</h3>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/blog">Blog Post</a></li>
                            <li><a href="/ourteam">Photo Gallery</a></li>
                        </ul>
                    </div>

                    <div className="footer-section contact-info">
                        <h3>Get In Touch</h3>
                        <a href="/contact">Contact Us</a>                        <p style={{cursor:'pointer'}}>Our Services</p>
                    </div>
                    </div>
                    <div className="footer-section address">
                        <h3>Address</h3>
                        <p>
                            D.No:40-28-0/2, Vasireddy Street, Gayathri Nagar, Vijayawada, Krishna NTR District, Andhra Pradesh State, India.
                        </p>
                    </div>
                    </div>

                    <div className="footer-section-newsletter">
                        <h3>Newsletter</h3>
                        <div className="newsletter-subscription">
                            <input type="email" placeholder="Enter Your Email" />
                            <button>Subscribe</button>
                        </div>
                        <p>Your Email Is Safe With Us. We Don't Spam.</p>
                        <h3>Follow Me</h3>
                        <div className="social-icons">
                            <a target='_blank' rel="noopener noreferrer" href="https://x.com/i/flow/login"><i className="fab fa-twitter"></i></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/accounts/login/?hl=en"><i className="fab fa-instagram"></i></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a>
                            <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footeer;